import './bootstrap.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home'
import CustomEngagementRingsUpload from './pages/CustomEngagementRingsUpload';
import ImageUpload from './pages/ImageUpload';
import ThankYou from './pages/ThankYou';
import ViewRequest from './pages/ViewRequest';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/custom-engagement-rings-upload" element={<CustomEngagementRingsUpload />} />
        <Route path="/imageupload" element={<ImageUpload />} />
        <Route path="/custom-thanks-page" element={<ThankYou />} />
        <Route path="/view-request" element={<ViewRequest />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
