import productBucketAWS from ".";

export const UploadFunction = async (file, path) => {
    if(typeof file === 'object' && !Array.isArray(file)) {
        return new Promise(async (resolve, reject) => {
            const params = {
                ACL: 'public-read',
                Key: path,
                ContentType: file.type,
                Body: file,
                Bucket: process.env.REACT_APP_S3_BUCKET
            }
            try {
                let result = await productBucketAWS.putObject(params).promise();
                if(result.$response.httpResponse.statusCode === 200) {
                    resolve("https://ola-portal.s3.amazonaws.com/" + path)
                } else {
                    reject('Something bad happen')
                }
            } catch(error) {
                reject('Something bad happen')
                console.log(error)
            }
        })
    } else {
        return file
    }
}


export function base64toBlob(base64Data, contentType = 'image/jpeg') {
    const base64 = base64Data.split(',')[1]; // Remove data URI scheme (e.g., "data:image/jpeg;base64,")

    try {
        const byteCharacters = atob(base64);
        const byteArrays = [];
        for(let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for(let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    } catch(error) {
        console.error('Error decoding base64 string:', error);
        return null;
    }
}

export const getBase64 = file => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = (error) => {
            reject(error);
        };
        reader.readAsDataURL(file);
    });
};