import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap'
import thumbImg from "../images/view-update-img.png"
import Slider from 'react-slick'
import asscher from "../images/asscher.svg"
import cushion from "../images/cushion.svg"
import emerald from "../images/emerald.svg"
import heart from "../images/heart.svg"
import marquise from "../images/marquise.svg"
import oval from "../images/oval.svg"
import pear from "../images/pear.svg"
import princess from "../images/princess.svg"
import radiant from "../images/radiant.svg"
import round from "../images/round.svg"
import silver from "../images/silver.png"
import roseGold from "../images/rose-gold.png"
import gold from "../images/gold.png"
import radioBlank from "../images/radio-no-fill.png"
import radioFill from "../images/radio-fill.png"
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import { REACT_APP_API_ENDPOINT } from '../config/aws-config';
import { UploadFunction, base64toBlob, getBase64 } from '../utils/s3/uploadImage';
import { QRCodeSVG } from 'qrcode.react';
import { io } from 'socket.io-client'
import { v4 as uuidv4 } from 'uuid';

let socket = io(process.env.REACT_APP_SOCKET_ENDPOINT);

const shapeSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 370,
            settings: {
                slidesToShow: 3,
            }
        },
    ]
}

const caratSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 6,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 370,
            settings: {
                slidesToShow: 2,
            }
        },
    ]
}


const CustomEngagementRingsUpload = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const userId = location.state;
    let urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    let email_pattern = /^([a-zA-Z0-9_\.\-+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9])+$/;
    let phone_pattern = /^(\(\d{3}\)\s?\d{3}-\d{4}|\d{3}-\d{3}-\d{4}|\d{10})$/;

    const [isLoading, setIsLoading] = useState(false)
    const [activeStep, setActiveStep] = useState(1)
    const [ringShapeImage, setRingShapeImage] = useState(round)
    const [metalColor, setMetalColor] = useState('')
    const [mainData, setMainData] = useState({
        picture: [],
        url: "",
        tellUs: "",
        metal: "",
        metalColor: "",
        ringSize: "",
        ringEngraving: "",
        shape: "",
        carat: "",
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        date: "",
        reason: "",
    })
    const [stepOneDataError, setStepOneDataError] = useState({
        picture: '',
        url: '',
        tellUs: '',
        ringSize: '',
        ringEngraving: '',
    })
    const [stepThreeDataError, setStepThreeDataError] = useState({
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        reason: '',
        date: '',
    })
    const [stepOneDataSubmitted, setStepOneDataSubmitted] = useState(false);
    const [stepThreeDataSubmitted, setStepThreeDataSubmitted] = useState(false);
    const [isOpen, setIsOpen] = useState(false)
    const [modalID, setModalID] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    const ringShapes = [
        {
            image: round,
            value: 'round',
        },
        {
            image: oval,
            value: 'oval',
        },
        {
            image: cushion,
            value: 'cushion',
        },
        {
            image: emerald,
            value: 'emerald',
        },
        {
            image: pear,
            value: 'pear',
        },
        {
            image: heart,
            value: 'heart',
        },
        {
            image: radiant,
            value: 'radiant',
        },
        {
            image: princess,
            value: 'princess',
        },
        {
            image: marquise,
            value: 'marquise',
        },
        {
            image: asscher,
            value: 'asscher',
        },
    ]
    const ringCarats = ['1.50ct', '2.00ct', '2.50ct', '3.00ct', '3.50ct', '4.00ct', '4.50ct', '5.00ct', '6.00ct', '7.00ct', '8.00ct', '9.00ct', '10.00ct']

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [activeStep])

    useEffect(() => {
        if(userId) {
            fetchData()
        } else {
            navigate("/")
        }
    }, [userId])

    useEffect(() => {
        const roomName = uuidv4()
        socket.emit('joinRoom', roomName);
        setModalID(roomName)

        socket.on('message', async (message) => {
            const pitureData = mainData?.picture;
            if(message[0]?.path) {
                setMainData((prevState) => ({
                    ...prevState,
                    picture: [...pitureData, ...message],
                }));
            } else {
                setMainData((prevState) => ({
                    ...prevState,
                    picture: [...message],
                }));

            }
            setIsOpen(false)
        });

    }, [mainData?.picture]);

    useEffect(() => {
        if(mainData?.picture?.length !== 0) {
            if(!mainData?.picture[0]?.path && mainData.step === 1 && isSubmit) {
                submitStepOneImageWithData({ ...mainData, step: 2, customerReviews: "" });
                setIsSubmit(false)
            }
        }
    }, [mainData, activeStep])


    const fetchData = () => {
        setIsLoading(true)
        axios.get(`${REACT_APP_API_ENDPOINT}${userId}`)
            .then(function (response) {
                if(response?.data?.data?.Item?.step === 1) {
                    setMainData({
                        ...response.data.data.Item,
                        metal: response.data.data.Item.metal ? response.data.data.Item.metal : '14k Gold',
                        metalColor: response.data.data.Item.metalColor ? response.data.data.Item.metalColor : 'silver',
                    })
                } else {
                    setMainData({
                        ...response.data.data.Item,
                        metal: response.data.data.Item.metal ? response.data.data.Item.metal : '14k Gold',
                        metalColor: response.data.data.Item.metalColor ? response.data.data.Item.metalColor : 'silver',
                        shape: response.data.data.Item.shape ? response.data.data.Item.shape : ringShapes[0].value,
                        carat: response.data.data.Item.carat ? response.data.data.Item.carat : ringCarats[0],
                    })
                }
                getMetalColor(response?.data?.data?.Item?.metalColor)
                setActiveStep(response.data.data.Item.step)
                setIsLoading(false)
            })
            .catch(function (error) {
                console.log(error);
                setIsLoading(false)
            });
    }

    const handleRingShape = (shape, shapeImg) => {
        setRingShapeImage(shapeImg)
        setMainData({ ...mainData, shape: shape })
    }

    const handleStepOneData = (name, value) => {

        let error = { ...stepOneDataError }
        if(stepOneDataSubmitted) {
            if(name === 'url') {
                if(!value) {
                    error = { ...error, url: "URL is required" };
                } else {
                    if(!urlRegex.test(value)) {
                        error = { ...error, url: "Enter a valid URL" };
                    } else {
                        error = { ...error, url: "" };
                    }
                }
            }
            if(name === 'tellUs') {
                if(!value) {
                    error = { ...error, tellUs: "Tell Us more is required" }
                } else {
                    error = { ...error, tellUs: "" }
                }
            }
            if(name === 'ringSize') {
                if(!value) {
                    error = { ...error, ringSize: "Ring size is required" }
                } else {
                    error = { ...error, ringSize: "" }
                }
            }
            if(name === 'ringEngraving') {
                if(!value) {
                    error = { ...error, ringEngraving: "Ring engraving is required" }
                } else {
                    error = { ...error, ringEngraving: "" }
                }
            }
        }
        setStepOneDataError(error)
        setMainData({ ...mainData, [name]: value })
    }

    const handleStepThreeData = (name, value) => {
        let error = { ...stepThreeDataError }
        if(stepThreeDataSubmitted) {
            if(name === 'email') {
                if(!value) {
                    error = { ...error, email: "Email is required" };
                } else {
                    if(!email_pattern.test(value)) {
                        error = { ...error, email: "Enter a valid email" };
                    } else {
                        error = { ...error, email: "" };
                    }
                }
            }
            if(name === 'firstName') {
                if(!value) {
                    error = { ...error, firstName: "First name is required" }
                } else {
                    error = { ...error, firstName: "" }
                }
            }
            if(name === 'lastName') {
                if(!value) {
                    error = { ...error, lastName: "Last name is required" }
                } else {
                    error = { ...error, lastName: "" }
                }
            }
            if(name === 'phoneNumber') {
                if(!value) {
                    error = { ...error, phoneNumber: "Mobile No. is required" };
                } else {
                    if(!phone_pattern.test(value)) {
                        error = { ...error, phoneNumber: "Enter a valid Mobile No." };
                    } else {
                        error = { ...error, phoneNumber: "" };
                    }
                }
            }
            if(name === 'reason') {
                if(!value) {
                    error = { ...error, reason: "Reason is required" }
                } else {
                    error = { ...error, reason: "" }
                }
            }
            if(name === 'date') {
                if(!value) {
                    error = { ...error, date: "Date is required" }
                } else {
                    error = { ...error, date: "" }
                }
            }
        }
        setStepThreeDataError(error)
        setMainData({ ...mainData, [name]: value })
    }

    const getSocketData = (photoList) => {
        photoList.forEach(obj => {
            if(typeof obj !== 'string') {
                obj.isUpload = true;
            }
        });
        socket.emit('joinRoom', modalID);
        socket.emit('sendMessage', { roomName: modalID, message: photoList });
    }

    const handleStepOneDataSave = async (e) => {
        e.preventDefault()
        setStepOneDataSubmitted(true)
        const errors = {};
        if(!mainData.tellUs || mainData.tellUs.length < 15) {
            errors.tellUs = "Tell Us more is required and must be at least 15 characters.";
        }
        if(!mainData.ringSize) {
            errors.ringSize = "Ring size is required.";
        }
        if(!mainData.ringEngraving || mainData.ringEngraving.length > 25) {
            errors.ringEngraving = "Ring engraving is required and must be less than 25 characters.";
        }
        if(!((mainData.url && urlRegex.test(mainData.url)) || mainData.picture.length > 0)) {
            errors.url = "Either URL or Picture is required.";
        }
        if(Object.keys(errors).length > 0) {
            setStepOneDataError(errors);
            return;
        }
        setIsLoading(true)
        setIsSubmit(true)
        let photoList = mainData.picture
        if(photoList?.length === 0) {
            axios.put(REACT_APP_API_ENDPOINT, { ...mainData, step: 2, customerReviews: "" })
                .then(function (response) {
                    setActiveStep(response.data.data.step)
                    fetchData()
                    setIsLoading(false)
                })
                .catch(function (error) {
                    console.log(error);
                    setIsLoading(false)
                });
        } else {
            getSocketData(photoList)
        }
    }

    const submitStepOneImageWithData = async (uplodData) => {
        if(mainData?.id) {
            await axios.put(REACT_APP_API_ENDPOINT, uplodData)
                .then(function (response) {
                    setActiveStep(response.data.data.step)
                    fetchData()
                    setIsLoading(false)
                })
                .catch(function (error) {
                    console.log(error);
                    setIsLoading(false)
                });
        }
    }

    const handleStepThreeDataSave = async (e) => {
        e.preventDefault()
        setStepThreeDataSubmitted(true)
        const errors = {};
        if(!(mainData.email && email_pattern.test(mainData.email))) {
            errors.email = "Enter a valid email";
        }
        if(!mainData.firstName) {
            errors.firstName = "First name is required.";
        }
        if(!mainData.lastName) {
            errors.lastName = "Last name is required.";
        }
        if(!(mainData.phoneNumber && phone_pattern.test(mainData.phoneNumber))) {
            errors.phoneNumber = "Phone Number a valid email";
        }
        if(!mainData.reason) {
            errors.reason = "Reason is required.";
        }
        if(!mainData.date) {
            errors.date = "Date is required.";
        }
        if(Object.keys(errors).length > 0) {
            setStepThreeDataError(errors);
            return;
        }

        setIsLoading(true) 
        axios.put(REACT_APP_API_ENDPOINT, { ...mainData, role: "customer", isSubmitted: true, customerReviews: "" })
            .then(function (response) {
                const targetOrigin = 'https://admin.dlgbportal.com/';
                window.parent.postMessage(response?.data?.data, targetOrigin);
                setActiveStep(response.data.data.step)
                setIsLoading(false)
                navigate('/custom-thanks-page', { state: response.data.data.orderId })
            })
            .catch(function (error) {
                console.log(error);
                setIsLoading(false)
            });
    }


    const handleStepTwoDataSave = () => {
        setIsLoading(true)
        axios.put(REACT_APP_API_ENDPOINT, { ...mainData, step: 3, customerReviews: "" })
            .then(function (response) {
                setActiveStep(response.data.data.step)
                fetchData()
                setIsLoading(false)
            })
            .catch(function (error) {
                console.log(error);
                setIsLoading(false)
            });
    }

    const handleCloseModal = () => {
        setIsOpen(false)
    }

    const handleRemoveImage = (index) => {
        let data = mainData?.picture?.filter((item, i) => i !== index);
        setMainData({ ...mainData, picture: data })
    }

    const getMetalColor = (color) => {
        if(color === 'silver') {
            setMetalColor(silver)
        } else if(color === 'gold') {
            setMetalColor(gold)
        } else {
            setMetalColor(roseGold)
        }
    }

    const handleImage = (event) => {
        const file = event.target.files[0];

        if(file) {
            getBase64(file)
                .then((base64String) => {
                    setMainData({ ...mainData, picture: [...mainData.picture, { image: base64String, path: `${userId}/${new Date().getTime()}`, isUpload: true }] });
                    setIsOpen(false)
                })
                .catch((error) => {
                    console.error('Error converting image to base64:', error);
                });
        }
    }

    return (
        <main>

            <div className='container my-5 small-container'>
                <h4 className="text-center mb-5 main-page-title">Custom Engagement Rings</h4>
                {activeStep === 1 &&
                    <Form className='step-1' onSubmit={handleStepOneDataSave}>
                        <Modal
                            show={isOpen}
                            onHide={handleCloseModal}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Upload Picture
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className='text-center'>
                                            <div className='text-center mt-3'>
                                                <QRCodeSVG value={`${window.location.origin}/imageupload?key:${modalID}`} />
                                                {/* <QRCodeSVG value={`http://192.168.29.219:3001/imageupload?key:${modalID}`} /> */}
                                            </div>
                                            <p className='mt-3 fs-14'>On Your mobile device,focus your camera on the QR code.Follow instructions to view this on your hand.</p>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <Form.Group className="position-relative mb-3 upload-file h-100" controlId="picture">
                                            <Form.Label className='picture-label mb-0 w-100 d-flex align-items-center h-100'>
                                                <div className='w-100'>
                                                    <span className='plus-icon d-flex align-items-center justify-content-center mx-auto'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                                            <path d="M4 12H20M12 4V20" stroke="#999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </span>
                                                    <span className="d-block upload-label-text text-center mt-2">Upload a picture</span>
                                                </div>
                                            </Form.Label>
                                            <Form.Control
                                                type="file"
                                                required
                                                name="picture"
                                                disabled={mainData?.picture?.length >= 2}
                                                onChange={(e) => handleImage(e)}
                                            // onChange={(e) => {
                                            //     setMainData({ ...mainData, picture: [...mainData.picture, { image: getBase64(e.target.files[0]), path: `${userId}/${new Date().getTime()}` }] });
                                            //     setIsOpen(false)
                                            // }}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <div>
                            <h5 className='label-title text-center mb-4'>Upload images</h5>
                            <div className='row'>
                                <div className='col-xl-7'>
                                    <div className="position-relative mb-3 upload-file">
                                        <Form.Label>Picture <span className='red-font'>*</span></Form.Label>
                                        <div className='picture-label mb-0 d-flex align-items-center' onClick={() => setIsOpen(mainData?.picture?.length >= 2 ? false : true)}>
                                            <span className='plus-icon d-flex align-items-center justify-content-center me-3'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                                    <path d="M4 12H20M12 4V20" stroke="#999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </span>
                                            <span className="d-block upload-label-text">Upload a picture of a design you like. jpg, jpeg, png or webp</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-5'>
                                    <div className='row ml-auto mr-auto'>
                                        <div className='col-6'>
                                            <div className='position-relative upload-view-thumb'>
                                                <img
                                                    src={typeof mainData?.picture[0] === "string" ? mainData?.picture[0] : mainData?.picture[0]?.image ? mainData?.picture[0]?.image : thumbImg}
                                                    // src={mainData?.picture[0]?.image ? mainData?.picture[0]?.image : thumbImg}
                                                    className='w-100'
                                                />
                                                <a className='upload-close-img d-block' onClick={() => handleRemoveImage(0)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                                        <path d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z" fill="#0F0F0F" />
                                                    </svg>
                                                </a>
                                            </div>
                                            <span className="d-block w-100 text-center mt-1 view-text">View 1*</span>
                                        </div>
                                        <div className='col-6'>
                                            <div className='position-relative upload-view-thumb'>
                                                <img
                                                    src={typeof mainData?.picture[1] === "string" ? mainData?.picture[1] : mainData?.picture[1]?.image ? mainData?.picture[1]?.image : thumbImg}
                                                    className='w-100'
                                                />
                                                <a className='upload-close-img' onClick={() => handleRemoveImage(1)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                                        <path d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z" fill="#0F0F0F" />
                                                    </svg>
                                                </a>
                                            </div>
                                            <span className="d-block w-100 text-center mt-1 view-text">View 2</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='border-line my-5 position-relative'></div>
                            <div className='row'>
                                <div className='col-xl-7'>
                                    <Form.Group controlId="url">
                                        <Form.Label>URL <span className='red-font'>*</span></Form.Label>
                                        <Form.Control
                                            type="link"
                                            name="url"
                                            placeholder="Add URL... "
                                            value={mainData?.url}
                                            onChange={(e) => handleStepOneData('url', e.target.value)}
                                            isInvalid={!!stepOneDataError.url}
                                        />
                                        <Form.Control.Feedback type="invalid">{stepOneDataError.url}</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className='border-bottom my-5 position-relative'></div>
                            <Form.Group className="mb-3" controlId="tellUs">
                                <Form.Label>Tell us more <span className='red-font'>*</span><span style={{ fontWeight: '400' }} className='fs-12'><i>(Min. 15 characters)</i></span></Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    name="tellUs"
                                    placeholder="Enter text..."
                                    value={mainData?.tellUs}
                                    onChange={(e) => handleStepOneData('tellUs', e.target.value)}
                                    isInvalid={!!stepOneDataError.tellUs}
                                />
                                <Form.Control.Feedback type="invalid">{stepOneDataError.tellUs}</Form.Control.Feedback>
                                <span className='d-block fs-12 mt-1'>{mainData?.tellUs?.length} characters.</span>
                                <span className='d-block fs-14'>Tell us more about what you do and don't want in the ring. Please be as specific as possible. The more information you provide, the more accurate we will be in designing your dream ring.</span>
                            </Form.Group>
                            <div className='border-bottom my-5'></div>
                            <div className='d-flex align-items-center d-flex-wrap mb-5'>
                                <h5 className="label-title label-with mb-0">Metal Type <span className='red-font'>*</span></h5>
                                <div className='field-content mt-md-0 mt-2'>
                                    <div className='metal-type d-flex flex-wrap align-items-center'>
                                        <div className='metals-type text-center'>
                                            <a className={mainData?.metal === '14k Gold' ? 'active' : ''} onClick={() => setMainData({ ...mainData, metal: '14k Gold' })}>
                                                <img src={mainData?.metal === '14k Gold' ? radioFill : radioBlank} />
                                                <span className='d-block'>14k Gold</span>
                                            </a>
                                        </div>
                                        <div className='metals-type text-center'>
                                            <a className={mainData?.metal === '18k Gold' ? 'active' : ''} onClick={() => setMainData({ ...mainData, metal: '18k Gold' })}>
                                                <img src={mainData?.metal === '18k Gold' ? radioFill : radioBlank} />
                                                <span className='d-block'>18k Gold</span>
                                            </a>
                                        </div>
                                        <div className='metals-type text-center'>
                                            <a className={mainData?.metal === 'platinum' ? 'active' : ''} onClick={() => setMainData({ ...mainData, metal: 'platinum' })}>
                                                <img src={mainData?.metal === 'platinum' ? radioFill : radioBlank} />
                                                <span className='d-block'>Platinum</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex align-items-center d-flex-wrap'>
                                <h5 className="label-title label-with mb-0">Metal Color <span className='red-font'>*</span></h5>
                                <div className='field-content mt-md-0 mt-2'>
                                    <div className='metal-color-slider d-flex flex-wrap align-items-center'>
                                        <div className='metals text-center'>
                                            <a className={mainData?.metalColor === 'silver' ? 'active' : ''} onClick={() => setMainData({ ...mainData, metalColor: 'silver' })}><img src={silver} /></a>
                                        </div>
                                        {
                                            mainData?.metal !== 'platinum' ?
                                                <>
                                                    <div className='metals text-center'>
                                                        <a className={mainData?.metalColor === 'gold' ? 'active' : ''} onClick={() => setMainData({ ...mainData, metalColor: 'gold' })}><img src={gold} /></a>
                                                    </div>
                                                    <div className='metals text-center'>
                                                        <a className={mainData?.metalColor === 'roseGold' ? 'active' : ''} onClick={() => setMainData({ ...mainData, metalColor: 'roseGold' })}><img src={roseGold} /></a>
                                                    </div>
                                                </>
                                                : ''
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className='my-5'></div>
                            <div className='d-flex align-items-center d-flex-wrap'>
                                <h5 className="label-title label-with mb-0">Ring Size <span className='red-font'>*</span></h5>
                                <div className='field-content mt-md-0 mt-2'>
                                    <div className='row align-items-center'>
                                        <div className='col-md-3 col-6'>
                                            <Form.Group className="mb-3" controlId="ringSize">
                                                <Form.Select
                                                    name='ringSize'
                                                    value={mainData?.ringSize}
                                                    onChange={(e) => handleStepOneData('ringSize', e.target.value)}
                                                    isInvalid={!!stepOneDataError.ringSize}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="3.00">3.00</option>
                                                    <option value="3.25">3.25</option>
                                                    <option value="3.50">3.50</option>
                                                    <option value="4.00">4.00</option>
                                                    <option value="4.25">4.25</option>
                                                    <option value="4.50">4.50</option>
                                                    <option value="4.75">4.75</option>
                                                    <option value="5.00">5.00</option>
                                                    <option value="5.25">5.25</option>
                                                    <option value="5.50">5.50</option>
                                                    <option value="5.75">5.75</option>
                                                    <option value="6.00">6.00</option>
                                                    <option value="6.25">6.25</option>
                                                    <option value="6.50">6.50</option>
                                                    <option value="6.75">6.75</option>
                                                    <option value="7.00">7.00</option>
                                                    <option value="7.25">7.25</option>
                                                    <option value="7.50">7.50</option>
                                                    <option value="7.75">7.75</option>
                                                    <option value="8.00">8.00</option>
                                                    <option value="8.25">8.25</option>
                                                    <option value="8.50">8.50</option>
                                                    <option value="8.75">8.75</option>
                                                    <option value="9.00">9.00</option>
                                                    <option value="9.25">9.25</option>
                                                    <option value="9.50">9.50</option>
                                                    <option value="9.75">9.75</option>
                                                    <option value="10.00">10.00</option>
                                                    <option value="10.25">10.25</option>
                                                    <option value="10.50">10.50</option>
                                                    <option value="10.75">10.75</option>
                                                    <option value="11.00">11.00</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">{stepOneDataError.ringSize}</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <Form.Group className="mt-4" controlId="ringEngraving">
                                    <Form.Label>Ring engraving <span className='red-font'>*</span></Form.Label>
                                    <span className='d-block fs-14 mb-1'>Complete your unique design by adding a special, personalized message or date inside your ring:</span>
                                    <Form.Control
                                        type="text"
                                        name="ringEngraving"
                                        placeholder="Your Message..."
                                        value={mainData?.ringEngraving}
                                        onChange={(e) => handleStepOneData('ringEngraving', e.target.value)}
                                        isInvalid={!!stepOneDataError.ringEngraving}
                                    />
                                    <Form.Control.Feedback type="invalid">{stepOneDataError.ringEngraving}</Form.Control.Feedback>
                                    <span className='d-block fs-14 mt-1'>{mainData?.ringEngraving?.length} of 25</span>
                                    <span className='d-block fs-12'>* Inappropriate and profane language or the unauthorized use of marks or wording could result in the cancellation of your order.</span>
                                </Form.Group>
                            </div>
                            <div className='my-5'></div>
                            <button type='submit' disabled={isLoading} className='site-btn btn'>{isLoading ? 'Loading...' : 'Continue'}</button>
                        </div>
                    </Form>
                }
                {activeStep === 2 &&
                    <div className='step-2'>
                        <div className='border-bottom pb-4'>
                            <h5 className="mb-4 pb-xl-3 main-diamond-image text-center">Your Center Shape & Carat</h5>
                            <div className='shape-icon mb-md-4 pb-3'>
                                <img src={ringShapeImage} />
                            </div>
                            <div className="shapecarat-price-dtl d-flex align-items-center justify-content-center">
                                <p className="mb-0 me-3 text-capitalize">{mainData.shape} - {mainData?.carat}</p>
                                {/* <p className="mb-0">Price - <strong>$2,200</strong></p> */}
                            </div>
                        </div>
                        <div className='py-5 border-bottom'>
                            <p className='mb-1'>Shape</p>
                            <Slider {...shapeSettings}>
                                {
                                    ringShapes.map((data, i) => {
                                        return (
                                            <div className='px-lg-4 px-3' key={i}>
                                                <a onClick={() => handleRingShape(data.value, data.image)} className={mainData.shape === data.value ? 'd-block shape-item active' : 'd-block shape-item'}>
                                                    <img src={data.image} />
                                                </a>
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        </div>
                        <div className='py-5 border-bottom'>
                            <p className='mb-1'><strong>Carat</strong></p>
                            <Slider {...caratSettings}>
                                {
                                    ringCarats.map((data, i) => {
                                        return (
                                            <div className='px-lg-4 px-3' key={i}>
                                                <a
                                                    className={mainData.carat === data ? 'active d-block carat-item' : 'd-block carat-item'}
                                                    onClick={() => setMainData({ ...mainData, carat: data })}
                                                >
                                                    {data}
                                                </a>
                                            </div>
                                        )
                                    })
                                }

                            </Slider>
                        </div>
                        <div className='d-flex justify-content-center pt-4'>
                            <button type='button' className='back-btn me-2' onClick={() => { setActiveStep(1); setMainData({ ...mainData, step: 1 }) }}>BACK</button>
                            <button type='button' disabled={isLoading} className='btn-small' onClick={handleStepTwoDataSave}>{isLoading ? 'Loading...' : 'CONTINUE'}</button>
                        </div>
                    </div>
                }
                {activeStep === 3 &&
                    <div className='final-step'>
                        <h5 className="mb-4 pb-xl-3 main-diamond-image text-center">Final Review</h5>
                        <div className='col-lg-5 col-md-8 mb-5'>
                            <div className='row ml-auto mr-auto'>
                                {
                                    mainData?.picture?.map((data, i) => {
                                        return (
                                            <div className='col-6 pe-4' key={i}>
                                                <div className='position-relative upload-view-thumb'>
                                                    <img src={data} className='w-100' />
                                                    <a className='edit-text image-edit-text' onClick={() => { setActiveStep(1); setMainData({ ...mainData, step: 1 }) }}>Edit</a>
                                                </div>
                                                <span className="d-block w-100 text-center mt-1 view-text">View {i + 1}*</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        {mainData?.url && <div className='col-md-6'>
                            <Form.Label className='d-flex align-items-end justify-content-between'>
                                <span>URL <span className='red-font'>*</span></span>
                                <a className='edit-text' onClick={() => { setActiveStep(1); setMainData({ ...mainData, step: 1 }) }}>Edit</a>
                            </Form.Label>
                            <div className='form-field-style url-link-text'>
                                {mainData?.url}
                            </div>
                        </div>}
                        <div className='my-5 border-bottom'></div>
                        <div>
                            <Form.Label className='d-flex align-items-end justify-content-between'><span>Tell us more</span><a className='edit-text' onClick={() => { setActiveStep(1); setMainData({ ...mainData, step: 1 }) }}>Edit</a></Form.Label>
                            <div className='form-field-style textarea-style'>
                                {mainData.tellUs}
                            </div>
                            <span className='d-block fs-14'>Tell us more about what you do and don't want in the ring. Please be as specific as possible. The more information you provide, the more accurate we will be in designing your dream ring.</span>
                        </div>
                        <div className='my-5'></div>
                        {mainData?.ringEngraving && <div className='col-md-6'>
                            <Form.Label className='d-flex align-items-end justify-content-between'>
                                <span>Ring Engraving <span className='red-font'>*</span></span>
                                <a className='edit-text' onClick={() => { setActiveStep(1); setMainData({ ...mainData, step: 1 }) }}>Edit</a>
                            </Form.Label>
                            <div className='form-field-style'>
                                {mainData?.ringEngraving}
                            </div>
                        </div>}

                        <div className='my-5 border-bottom'></div>
                        <div className='row align-items-center'>
                            <div className='col-md-6 mb-5'>
                                <div className='d-flex align-items-center'>
                                    <p className='mb-0 title final-label me-3'><strong>Your Shape</strong></p>
                                    <div className='final-content'>
                                        <div className='d-flex shape-item-show '>
                                            <img src={(ringShapes.find(shape => shape.value === mainData?.shape)).image} className='m-0' />
                                            <a className='edit-text' onClick={() => { setActiveStep(2); setMainData({ ...mainData, step: 2 }) }}>Edit</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 mb-5'>
                                <div className='d-flex align-items-center'>
                                    <p className='mb-0 title final-label me-3'><strong>Your Metal Color</strong></p>
                                    <div className='final-content'>
                                        <div className='d-flex shape-item-show '>
                                            <img src={metalColor} className='m-0' />
                                            <a className='edit-text' onClick={() => { setActiveStep(1); setMainData({ ...mainData, step: 1 }) }}>Edit</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 mb-5'>
                                <div className='d-flex align-items-center'>
                                    <p className='mb-0 title final-label me-3'><strong>Your Metal</strong></p>
                                    <div className='final-content'>
                                        <div className='d-flex shape-item-show '>
                                            <div className='me-2 field-style-2'>{mainData.metal}</div>
                                            <a className='edit-text' onClick={() => { setActiveStep(1); setMainData({ ...mainData, step: 1 }) }}>Edit</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 mb-5'>
                                <div className='d-flex align-items-center'>
                                    <p className='mb-0 title final-label me-3'><strong>Your Carat</strong></p>
                                    <div className='final-content'>
                                        <div className='d-flex shape-item-show '>
                                            <div className='me-2 field-style-2'>{mainData.carat}</div>
                                            <a className='edit-text' onClick={() => { setActiveStep(2); setMainData({ ...mainData, step: 2 }) }}>Edit</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 mb-5'>
                                <div className='d-flex align-items-center'>
                                    <p className='mb-0 title final-label me-3'><strong>Your Ring Size</strong></p>
                                    <div className='final-content'>
                                        <div className='d-flex shape-item-show '>
                                            <div className='me-2 field-style-2'>{mainData.ringSize}</div>
                                            <a className='edit-text' onClick={() => { setActiveStep(1); setMainData({ ...mainData, step: 1 }) }}>Edit</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mb-5 border-bottom'></div>
                        <h5 className="mb-2 main-diamond-image text-center">Your Information</h5>
                        <p className='text-center fs-14'>Please log in or create an account. We will email your design directly to you and also make it available in your account, allowing you to view its status at your convenience</p>
                        <Form className='mt-5' onSubmit={handleStepThreeDataSave}>
                            <div className='row'>
                                <div className='col-md-6 mb-4'>
                                    <Form.Group controlId="email">
                                        <Form.Label>Email Id</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            placeholder="Email Id"
                                            value={mainData?.email}
                                            onChange={(e) => handleStepThreeData('email', e.target.value)}
                                            isInvalid={!!stepThreeDataError.email}
                                        />
                                        <Form.Control.Feedback type="invalid">{stepThreeDataError.email}</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <Form.Group controlId="firstName">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="firstName"
                                            placeholder="First name"
                                            value={mainData?.firstName}
                                            onChange={(e) => handleStepThreeData('firstName', e.target.value)}
                                            isInvalid={!!stepThreeDataError.firstName}
                                        />
                                        <Form.Control.Feedback type="invalid">{stepThreeDataError.firstName}</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <Form.Group controlId="lastName">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="lastName"
                                            placeholder="Last name"
                                            value={mainData?.lastName}
                                            onChange={(e) => handleStepThreeData('lastName', e.target.value)}
                                            isInvalid={!!stepThreeDataError.lastName}
                                        />
                                        <Form.Control.Feedback type="invalid">{stepThreeDataError.lastName}</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <Form.Group controlId="phoneNumber">
                                        <Form.Label>Mobile No</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="phoneNumber"
                                            placeholder="Mobile No"
                                            value={mainData?.phoneNumber}
                                            onChange={(e) => handleStepThreeData('phoneNumber', e.target.value)}
                                            isInvalid={!!stepThreeDataError.phoneNumber}
                                        />
                                        <Form.Control.Feedback type="invalid">{stepThreeDataError.phoneNumber}</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                {/* <div className='col-md-6 mb-4'>
                                    <Form.Group controlId="password">
                                        <Form.Label>Create Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            placeholder="Create Password"
                                            value={mainData?.password}
                                            onChange={(e) => handleStepThreeData('password', e.target.value)}
                                            isInvalid={!!stepThreeDataError.password}
                                        />
                                        <Form.Control.Feedback type="invalid">{stepThreeDataError.password}</Form.Control.Feedback>
                                    </Form.Group>
                                </div> */}
                                <div className='col-md-6 mb-4'>
                                    <Form.Group controlId="reason">
                                        <Form.Label>Reason for Custom Request</Form.Label>
                                        <Form.Select
                                            name='reason'
                                            value={mainData?.reason}
                                            onChange={(e) => handleStepThreeData('reason', e.target.value)}
                                            isInvalid={!!stepThreeDataError.reason}
                                        >
                                            <option value="">Select</option>
                                            <option value="Proposal">Proposal</option>
                                            <option value="Wedding">Wedding</option>
                                            <option value="Anniversary">Anniversary</option>
                                            <option value="Birthday">Birthday</option>
                                            <option value="Push Present">Push Present</option>
                                            <option value="Holiday Present">Holiday Present</option>
                                            <option value="Self-purchase">Self-purchase</option>
                                            <option value="Other">Other</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{stepThreeDataError.reason}</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <Form.Group controlId="date">
                                        <Form.Label>Date Needed</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="date"
                                            placeholder="Date Needed"
                                            value={mainData?.date}
                                            onChange={(e) => handleStepThreeData('date', e.target.value)}
                                            isInvalid={!!stepThreeDataError.date}
                                        />
                                        <Form.Control.Feedback type="invalid">{stepThreeDataError.date}</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>
                            <button type="submit" disabled={isLoading} className="site-btn btn text-uppercase">{isLoading ? 'Loading...' : 'Submit Request'}</button>
                        </Form>
                    </div>
                }

            </div>
        </main>
    )
}

export default CustomEngagementRingsUpload