import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import engagement from "../images/rings.jpg"

const ThankYou = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const orderId = location.state;

    useEffect(() => {
        if(!orderId) {
            navigate("/")
        }
    }, [orderId])

    return (
        <>
            <section className='position-relative'>
                <div className='container'>
                    <div className='py-5'>
                        <p className='breadcrumb mb-0'> <Link to="/">Home</Link> | Custom Engagement Rings Design Submitted</p>
                    </div>
                </div>
                <div className='thank-you-image'>
                    <img src={engagement} alt='ring' />
                </div>
                <div className='ring-section-content'>
                    <div className='container text-center '>
                        <div className='w-100 my-5'>
                            <h3 className='ring-section-title mb-5'>Thank you for sending us your inspiration!</h3>
                            <h5 className='height-light-text mb-4'>Your Truly Custom by Grown Brilliance request ID is <b>{orderId}</b>. Please jot it down for your reference.</h5>
                            <p className='small-text mb-1'>Our Jewlery Experts will be curating your perfect engagement rings for you.</p>
                            <p className='small-text mb-4'>Within four (4) business days, we'll send you an email with a link to your design. Review your design closely. You can either order it right online or speak to one of our Jewelry Specialists about any changes you would like to make.</p>
                            <button type='button' onClick={() => navigate('/')} className='site-btn  btn'>Continue Shopping</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ThankYou