import AWS from 'aws-sdk';

AWS.config.update({
    region: process.env.REACT_APP_S3_REGION
})

const productBucketAWS = new AWS.S3();

export default productBucketAWS;

