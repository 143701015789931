import React, { useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { REACT_APP_API_ENDPOINT } from '../config/aws-config';

const Home = () => {
    const navigate = useNavigate();
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const handleGetStarted = () => {
        axios.post(REACT_APP_API_ENDPOINT, {})
            .then(function (response) {
                navigate("/custom-engagement-rings-upload", { state: response.data.data.id })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handlePlayPause = () => {
        if(isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    return (
        <main className='main-home'>
            <section className='home-banner'>
                <img src={require('../images/home-banner.jpg')} alt='home banner' className='w-100 d-md-block d-none' />
                <img src={require('../images/home-banner-mob.jpg')} alt='home banner' className='w-100 d-md-none d-block' />
            </section>
            <section className='py-5'>
                <div className='container home-small-container'>
                    <h3 className='home-main-title text-center mb-3 mb-md-0'>Create Something Stunning</h3>
                    <p className='text-center'>From custom engagement rings to personalized fashion rings, necklaces and bracelets, creating a one-of-a-kind design has never been easier. </p>
                    <button type='button' onClick={handleGetStarted} className='home-dark-btn mx-auto d-block mt-4'>Create Now</button>
                </div>
                <div className='container home-container mt-5 pt-4'>
                    <div className='row align-items-center'>
                        <div className='col-md-7 px-md-3 px-0'>
                            <img src={require('../images/day1.jpg')} alt='day 1' className='w-100' />
                        </div>
                        <div className='col-md-5 ps-xl-5 mt-md-0 mt-4 px-3'>
                            <label className='day-text'>Day 1</label>
                            <h3 className='home-title my-4'>Share Your Vision</h3>
                            <p>Share sketches and details of your dream style to guide our crafting process. Your insights will help us in crafting the perfect piece tailored to your dreams.</p>
                        </div>
                    </div>
                    <div className='row align-items-center pt-5'>
                        <div className='col-md-5 pe-xl-5 mt-md-0 mt-4 px-3'>
                            <label className='day-text'>Day 2-6</label>
                            <h3 className='home-title my-4'>Approval Process</h3>
                            <p>Upon receiving a product link from our design team, you can either proceed with your purchase directly or book a Virtual Appointment for further clarification.</p>
                        </div>
                        <div className='col-md-7 col-md-7 px-md-3 px-0 home-right-img-col'>
                            <img src={require('../images/day2-6.jpg')} alt='day 1' className='w-100' />
                        </div>
                    </div>
                    <div className='row align-items-center pt-5'>
                        <div className='col-md-7 col-md-7 px-md-3 px-0'>
                            <img src={require('../images/day12-17.jpg')} alt='day 12-17' className='w-100' />
                        </div>
                        <div className='col-md-5 ps-xl-5 mt-md-0 mt-4 px-3'>
                            <label className='day-text'>Days 12-17</label>
                            <h3 className='home-title my-4'>Crafted to Perfection</h3>
                            <p>Expect to receive your custom piece in about 17 business days. During this time, we craft and perfect your creation to ensure it surpasses your expectations.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='pb-5 mb-md-5 mt-xl-4'>
                <div className='position-relative'>
                    <button type='button' onClick={handlePlayPause} className='video-control d-block'>
                        {
                            !isPlaying ?
                            <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" stroke="#ffffff"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M716.8 512l-384-256v512z" fill="#ffffff" fillRule="evenodd"></path></g></svg>
                            :
                            <svg fill="#ffffff" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M18.432 7.5h4.547v17h-4.547zM9.022 7.5h4.545v17H9.022z"></path></g></svg>
                        }
                    </button>
                    <video ref={videoRef} className='w-100 home-video' poster={require('../images/video-poster.jpg')}>
                        <source src="https://ola-portal.s3.amazonaws.com/OLA_custom_1728x550_dktp.mp4" type="video/mp4" />
                    </video>
                </div>
            </section>
            <section className='bottom-banner position-relative'>
                <img src={require('../images/bottom-banner.jpg')} alt='home banner' className='w-100 d-block' />
                <div className='bottom-banner-content px-md-0 px-4'>
                    <div className='container home-container'>
                        <div className='bottom-banner-content-inner'>
                            <h3 className='home-title mb-4 text-center'>Receive Your Jewelry in as Few as 14 Days</h3>
                            <button type='button' onClick={handleGetStarted} className='home-light-btn mx-auto d-block'>Get Started</button>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Home