import React, { useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import { useLocation } from 'react-router-dom';

let socket = io(process.env.REACT_APP_SOCKET_ENDPOINT);

const ImageUpload = () => {
  const [data, setData] = useState('')
  const [roomName, setRoomName] = useState('')

  const location = useLocation();


  useEffect(() => {
    let locationData = location.search.substring(5)
    setRoomName(locationData)
  }, [location])


  useEffect(() => {
    if (data) {
      socket.emit('joinRoom', roomName);
      socket.emit('sendMessage', { roomName: roomName, message: [{image: data , path: `${roomName}/${new Date().getTime()}`, isUpload: false}] });
    }
  }, [data])


  const handleChange = (file) => {
    // console.log(file)
    let files = file.target.files;
    if (FileReader && files && files.length) {
      var fr = new FileReader();
      fr.onload = function () {
        setData(fr.result)
      }
      fr.readAsDataURL(files[0]);
    }
  }

  return (
    <>
      <div>
        <label className='site-btn d-block add-photo-btn' htmlFor="upload-btn">ADD A PHOTO</label>
        <input id="upload-btn" className='button-style' type={"file"} onChange={handleChange} />
      </div>
    </>
  )
}

export default ImageUpload
